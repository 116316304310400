<template>

<div class="grid-page-wrapper">
    <h1 class="display-4">Products</h1>
    <hr>

    <ModalFormProduct 
        idModal="modal-product-create" 
        title="New product" 
        submitButtonText="Add product"
        @submit="createProduct"/>

    <ModalFormProduct 
        idModal="modal-product-edit" 
        :title="'Editar producto: ' + editData.reference" 
        submitButtonText="Edit product"
        :formData="editData"
        @submit="editProduct"/>

    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-success"
                    data-bs-toggle="modal" 
                    data-bs-target="#modal-product-create"
             ><i class="fa-solid fa-plus"></i> Add</button>
            <button class="btn btn-secondary dropdown-toggle mx-2"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
</div>
</template>


<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'

import TableButtonEdit from "../../shared/components/TableButtonEdit"
import TableButtonDelete from "../../shared/components/TableButtonDelete"
import ModalFormProduct from '../components/ModalFormProduct.vue'
import { getProducts, insertProduct, deleteProduct, updateProduct } from '../helpers/endpoints'
import { defaultButtonColumnDef } from '../../shared/helpers/GridHelpers'


export default {
    components: {
        AgGridVue,
        ModalFormProduct
    },
    data() {
        return {
            columnDefs: [
                { headerName: "Reference", flex: 1, field: "reference", filter: 'agTextColumnFilter' },
                { 
                    headerName: "Obsolete", field: "is_obsolete",
                    // filter: 'agTextColumnFilter',
                    cellRenderer: (params) => {
                        if (params.value) return '<i class="fa-solid fa-circle-check text-success"></i>'
                        else return '<i class="fa-solid fa-circle-xmark text-danger"></i>'
                    }
                },
                {
                    field: 'id',
                    cellRenderer: 'btnEditRenderer',
                    cellRendererParams: {
                        action: this.openEditModal
                    },
                    ...defaultButtonColumnDef
                },
                {
                    field: 'id',
                    cellRenderer: 'btnDeleteRenderer',
                    cellRendererParams: {
                        action: this.removeProduct
                    },
                    ...defaultButtonColumnDef
                }
            ],
            defaultColDef: {
                width: 125,
                resizable: false,
                floatingFilter: true,
                sortable:true
            },
            frameworkComponents: {
                btnEditRenderer: TableButtonEdit,
                btnDeleteRenderer: TableButtonDelete
            },
            rowData: [],
            editData: {}
        }
    },

    computed: {
    },

    methods: {

        renderTable() {
            getProducts().then((response) => {
                this.rowData = response;
                console.log(this.rowData );
            })
        },

        openEditModal(formData) {
            console.log(formData);
            this.editData = formData
            let modalEdit = new Modal(document.getElementById('modal-product-edit'))
            modalEdit.show()
        },

        async createProduct($event, formData) {
            console.log("Creating product");
            console.log($event);
            console.log(formData);
            insertProduct(formData).then((response) => {
                console.log(response);
                this.renderTable()

                let modalCreate = Modal.getInstance(document.getElementById('modal-product-create'))
                modalCreate.hide()
                
                Swal.fire(
                    'Good job!',
                    'Product added with success',
                    'success'
                )
            })
        },

        editProduct($event, formData) {
            console.log("Editing product");
            console.log($event);
            console.log(formData);
            updateProduct(this.editData.id, formData).then((response) => {
                console.log(response);
                this.renderTable()

                let modalEdit = Modal.getInstance(document.getElementById('modal-product-edit'))
                modalEdit.hide()
                
                Swal.fire(
                    'Good job!',
                    'Product edited with success',
                    'success'
                )
            })

        },

        removeProduct({ id }) {
            console.log(`Deleting product ${id}`);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    deleteProduct(id)
                        .then((response)=> {
                            console.log(response)
                            this.renderTable()
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'Product has been deleted.',
                                'success'
                            )
                        })
                    
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your product is safe :)',
                        'error'
                    )
                }
            })
        },
       
        onBtExport() {
            console.log("Export to CSV")
            this.gridApi.exportDataAsCsv({
                columnSeparator: ',',
                columnKeys: [
                    'reference'
                ]
            });
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
 
            this.renderTable()
        } 
    },

    watch: {
        selectSelection(newSelection, oldSelection) {
            if (newSelection != oldSelection) {
                this.renderTable(newSelection.code)
            }
        },

    },
}
</script>
<style lang="scss" scoped>
    // @import "vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4.scss";
</style>