<template>
<div class="modal fade" :id="idModal" :ref="idModal" tabindex="-1" aria-labelledby="modal-product" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form class="was-validated">
                <div class="mb-3">
                    <label for="input-reference" class="form-label">Reference</label>
                    <input type="text" class="form-control is-invalid" 
                           v-model="reference"
                           id="input-reference" 
                           aria-describedby="referenceHelp"
                           required>
                    <div id="referenceHelp" class="form-text">Set product unique identifier.</div>
                </div>
                <div class="form-check">
                    <input v-model="is_obsolete" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                    <label class="form-check-label" for="flexCheckChecked">
                        Obsolete
                    </label>
                </div>
            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="submit">{{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            required: true,
            type: String
        },
        formData: {
            type: Object
        }
    },

    emits: ['submit'],

    setup(props, context) {
        const reference = ref('')
        const is_obsolete = ref(false)

        const cleanForm = () =>  {
            reference.value = ''
        }

        watch(() => props.formData, (newFormData) => {
            console.log(newFormData);
            if (newFormData) {
                reference.value = newFormData.reference
                is_obsolete.value = newFormData.is_obsolete
            }
        })

        onMounted(() => {
            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })

        })

        return {
            reference,
            is_obsolete,

            submit: ($event) => {
                const formData = { reference: reference.value, is_obsolete: is_obsolete.value }
                context.emit('submit', $event, formData)
            }
        }
    },
}
</script>

<style>

</style>